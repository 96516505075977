import * as ErrorMessages from './errorMessages';
import { isNumeric as isNum, isString, thisYear } from '../helpers/types';
import nhsValidator from 'nhs-number-validator';
import moment from 'moment';
import domPurify from 'dompurify';
import {
  emptyPair,
  getContextKey,
  getFieldInfo,
  getFieldInfoFromName,
  getQuestionIdFromAlias
} from '../features/questionnaire/unrefactored/fields';
import { store } from '../stores/store';

export const nhsChecksum = number => {
  return nhsValidator.validate(number) ? null : ErrorMessages.nhsChecksum;
};

export const isRequired = text => {
  if (isNum(text)) {
    return null;
  }
  if (text && text.length > 0) {
    return null;
  } else {
    return ErrorMessages.isRequired;
  }
};

export const isNumeric = text => {
  if (isString(text)) {
    text = parseFloat(text);
  }
  if (isNum(text)) {
    return null;
  } else {
    return ErrorMessages.isNumeric;
  }
};

export const isWholeNumber = text => {
  if (isString(text)) {
    text = parseFloat(text);
  }
  if (isNum(text) && text % 1 === 0) {
    return null;
  } else {
    return ErrorMessages.isWholeNumber;
  }
};

export const isPositive = text => {
  if (isString(text)) {
    text = parseFloat(text);
  }
  if (isNum(text) && text >= 0) {
    return null;
  } else {
    return ErrorMessages.isPositive;
  }
};

export const isBetween = (input, start, end) => {
  if (isString(input)) {
    input = parseFloat(input);
  }

  if (input >= start && input <= end) {
    return null;
  } else {
    return ErrorMessages.isBetween(start, end);
  }
};

export const isUKDateOrYear = text => {
  if (text == null) {
    return ErrorMessages.isUKDate;
  }

  text = text.toString();
  const upperYearBound = thisYear() + 1;

  try {
    if (text.indexOf('/') !== -1) {
      const parts = text.split('/');
      if (parts.length !== 3 || text.length < 8 || text.length > 10) {
        return ErrorMessages.isUKDateOrYear;
      }

      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10);
      const yearPart = parseInt(parts[2], 10);

      if (
        day > 0 &&
        day <= 31 &&
        month > 0 &&
        month <= 12 &&
        yearPart >= 1800 &&
        yearPart <= upperYearBound
      ) {
        // Test we can make a real date out of it
        try {
          const testDate = new moment(
            `${month}-${day}-${yearPart}`,
            'M-D-YYYY'
          );
          if (!testDate.isValid()) return ErrorMessages.isUKDateOrYear;
        } catch {
          return ErrorMessages.isUKDateOrYear;
        }

        return null;
      } else {
        if (!(yearPart >= 1800 && yearPart <= upperYearBound)) {
          return ErrorMessages.isUKYearInRange;
        } else {
          return ErrorMessages.isUKDateOrYear;
        }
      }
    } else {
      const year = parseInt(text, 10);

      if (text !== year.toString()) {
        return ErrorMessages.isUKDateOrYear;
      }

      if (year >= 1800 && year <= upperYearBound) {
        return null;
      } else {
        return ErrorMessages.isUKYearInRange;
      }
    }
  } catch (err) {
    return ErrorMessages.isUKDateOrYear;
  }
};

export const isUKDate = text => {
  if (text == null) {
    return ErrorMessages.isUKDate;
  }

  text = text.toString();

  try {
    const parts = text.split('/');
    if (parts.length !== 3) {
      return ErrorMessages.isUKDate;
    }

    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10);
    const yearPart = parseInt(parts[2], 10);

    if (
      day > 0 &&
      day <= 31 &&
      month > 0 &&
      month <= 12 &&
      yearPart >= 1800 &&
      yearPart <= thisYear()
    ) {
      return null;
    } else {
      return ErrorMessages.isUKYearInRange;
    }
  } catch (err) {
    return ErrorMessages.isUKDate;
  }
};

export const isEmail = text => {
  let re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (re.test(text)) {
    return null;
  } else {
    return ErrorMessages.isEmail(text);
  }
};

export const mustMatch = (field, fieldName) => {
  return (text, state) => {
    return state[field] === text ? null : ErrorMessages.mustMatch(fieldName);
  };
};

export const minLength = length => {
  return text => {
    return text.length >= length ? null : ErrorMessages.minLength(length);
  };
};

export const isSafeForRender = dirty => {
  const clean = domPurify.sanitize(dirty);
  return clean === dirty ? null : ErrorMessages.unsafeText;
};

export const isOnlyDateChars = text => {
  if (text == null) {
    return ErrorMessages.isOnlyDateChars;
  }

  text = text.toString();

  try {
    const reg = /^[0-9/]+$/;
    if (!reg.test(text)) {
      return ErrorMessages.isOnlyDateChars;
    } else {
      return null;
    }
  } catch (err) {
    return ErrorMessages.isOnlyDateChars;
  }
};

export const ageValidation = (value, state, fieldName) => {
  const questionnaireState = store.getState().newQuestionnaire;
  const responses = questionnaireState.responses;
  const sectionContexts = questionnaireState.contexts;

  const fieldInfo = getFieldInfoFromName(fieldName);
  const age = parseInt(value, 10);

  let dob = null;
  let dobDate = null;
  let dobFieldInfo = null;
  let dobResponse = null;

  let dod = null;
  let dodDate = null;

  const yearOfDeathQuestionId = getQuestionIdFromAlias('YearOfDeath');
  const relativeDobQuestionId = getQuestionIdFromAlias('RelativeDob');
  const ageOfDiagnosisQuestionId = getQuestionIdFromAlias('AgeOfDiagnosis');

  if (fieldInfo.questionId === yearOfDeathQuestionId) {
    // Use current field info to find the dob question
    dobFieldInfo = getFieldInfo(
      relativeDobQuestionId,
      fieldInfo.sectionId,
      fieldInfo.sectionInstanceId,
      fieldInfo.sectionIndex,
      fieldInfo.sectionDepth
    );

    dobResponse = responses.get(dobFieldInfo.fieldName, emptyPair);
    dob = dobResponse?.value;
    dobDate = null;

    if (isUKDate(dob) === null) {
      dobDate = moment.utc(dob, 'DD-MM-YYYY');
    } else if (isUKDateOrYear(dob) === null) {
      dobDate = moment.utc(`01-01-${dob}`, 'DD-MM-YYYY');
    }

    dod = value;

    if (isUKDate(dod) === null) {
      dodDate = moment.utc(dod, 'DD-MM-YYYY');
    } else if (isUKDateOrYear(dod) === null) {
      dodDate = moment.utc(`01-01-${dod}`, 'DD-MM-YYYY');
    }

    // Check dod not < dob
    if (!dodDate) return null;

    if (dobDate && dobDate.isAfter(dodDate)) {
      return ErrorMessages.isDateOfDeathBeforeBirth;
    }
  } else if (fieldInfo.questionId === ageOfDiagnosisQuestionId) {
    // Here will we need to pull out the sectionContext so we can figure out the section this one came from
    // which will be the parent relationship loop got from SectionSourceField - get the dob from there
    const contextKey = getContextKey(
      fieldInfo.sectionId,
      fieldInfo.sectionInstanceId
    );
    const sectionContext = sectionContexts?.get(contextKey, null);
    const sourceFieldName = sectionContext?.get('SectionSourceField', null);
    const sourceField = getFieldInfoFromName(sourceFieldName);

    dobFieldInfo = getFieldInfo(
      relativeDobQuestionId,
      sourceField.sectionId,
      sourceField.sectionInstanceId,
      sourceField.sectionIndex,
      sourceField.sectionDepth
    );

    dobResponse = responses.get(dobFieldInfo.fieldName, emptyPair);
    dob = dobResponse?.value;
    dobDate = null;

    if (isUKDate(dob) === null) {
      dobDate = moment.utc(dob, 'DD-MM-YYYY');
    } else if (isUKDateOrYear(dob) === null) {
      dobDate = moment.utc(`01-01-${dob}`, 'DD-MM-YYYY');
    }

    const now = moment();
    var dateOfDiagnosis = dobDate && dobDate.add(age, 'y');

    // Check dobDate + age of diagnosis <= now
    if (dateOfDiagnosis && dateOfDiagnosis.isAfter(now)) {
      return ErrorMessages.isDiagnosisDateInTheFuture;
    }

    const dodFieldInfo = getFieldInfo(
      yearOfDeathQuestionId,
      sourceField.sectionId,
      sourceField.sectionInstanceId,
      sourceField.sectionIndex,
      sourceField.sectionDepth
    );

    const dodResponse = responses.get(dodFieldInfo.fieldName, emptyPair);

    dod = dodResponse?.value;
    dodDate = null;

    if (isUKDate(dod) === null) {
      dodDate = moment.utc(dod, 'DD-MM-YYYY');
    } else if (isUKDateOrYear(dod) === null) {
      // dod value is approximate year, 
      dodDate = moment.utc(`01-01-${dod}`, 'DD-MM-YYYY');      
      // therefore, also set date of diagnosis with default day and month of 01-01 to get correct validation result
      var yearOfDiagnosis = dateOfDiagnosis.format('YYYY');
      dateOfDiagnosis = moment.utc(`01-01-${yearOfDiagnosis}`, 'DD-MM-YYYY');
    }

    // Check date of birth + age of diagnosis <= date of death
    if (dateOfDiagnosis && dodDate && dateOfDiagnosis.isAfter(dodDate)) {
      return ErrorMessages.isDiagnosisAfterDeath;
    }
  }

  return null;
};
