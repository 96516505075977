import { isBoolean, isNumeric } from '../../../helpers/types';
import { store } from '../../../stores/store';
import { SectionStatusConstants } from '../../../helpers/constants';

export const fieldStart = '{{';
export const fieldEnd = '}}';
export const fieldSeparator = 'X';

export const getContextKey = (sectionId, sectionInstanceId) => {
  const padSection = sectionId.toString().padStart(2, '0');
  const padInstance = sectionInstanceId.toString().padStart(2, '0');

  return `SECTION${fieldSeparator}${padSection}${fieldSeparator}${padInstance}`;
};

export const getSectionInfoFromKey = contextKey => {
  const keyInfo = contextKey.split(fieldSeparator);
  let result = {};

  if (keyInfo.length === 3) {
    result = {
      sectionKey: contextKey,
      sectionId: parseInt(keyInfo[1], 10),
      sectionInstanceId: parseInt(keyInfo[2], 10)
    };
  }

  return result;
};

export const containsField = input => {
  return (
    input && input.indexOf(fieldStart) !== -1 && input.indexOf(fieldEnd) !== -1
  );
};

export const getFieldName = (
  questionId,
  sectionId,
  sectionInstanceId,
  sectionIndex,
  sectionDepth
) => {
  const padQuestion = questionId.toString().padStart(2, '0');
  const padSection = sectionId.toString().padStart(2, '0');

  if (sectionInstanceId == null) {
    return `RESPONSE${fieldSeparator}${padSection}${fieldSeparator}${padQuestion}`;
  } else {
    const padInstance = sectionInstanceId.toString().padStart(2, '0');
    const padIndex = sectionIndex.toString().padStart(2, '0');
    const padDepth = sectionDepth.toString().padStart(2, '0');
    return `RESPONSE${fieldSeparator}${padSection}${fieldSeparator}${padQuestion}${fieldSeparator}${padInstance}${fieldSeparator}${padIndex}${fieldSeparator}${padDepth}`;
  }
};

export const getFieldInfo = (
  questionId,
  sectionId,
  sectionInstanceId,
  sectionIndex,
  sectionDepth
) => {
  return {
    fieldName: getFieldName(
      questionId,
      sectionId,
      sectionInstanceId,
      sectionIndex,
      sectionDepth
    ),
    questionId: questionId,
    sectionId: sectionId,
    sectionInstanceId: sectionInstanceId,
    sectionIndex: sectionIndex,
    sectionDepth: sectionDepth
  };
};

export const getFieldInfoFromName = fieldName => {
  const fieldInfo = fieldName.split(fieldSeparator);
  let result = {};

  if (fieldInfo.length > 0) {
    switch (fieldInfo.length) {
      case 3:
        result = {
          fieldName: fieldName,
          questionId: parseInt(fieldInfo[2], 10),
          sectionId: parseInt(fieldInfo[1], 10)
        };

        break;
      case 6:
        result = {
          fieldName: fieldName,
          questionId: parseInt(fieldInfo[2], 10),
          sectionId: parseInt(fieldInfo[1], 10),
          sectionInstanceId: parseInt(fieldInfo[3], 10),
          sectionIndex: parseInt(fieldInfo[4], 10),
          sectionDepth: parseInt(fieldInfo[5], 10)
        };
        break;
      default:
        result = {};
    }
  }

  return result;
};

export const getNextResponseFieldInfo = (currentFieldInfo, responses) => {
  const responseKeys = responses.keySeq();
  const currentFieldIndex = responseKeys.findIndex(
    k => k === currentFieldInfo.fieldName
  );

  // Current field not present or no preceding field
  if (
    currentFieldIndex === -1 ||
    currentFieldIndex + 1 > responseKeys.size - 1
  ) {
    return null;
  }

  const nextField = responses
    .slice(currentFieldIndex + 1, currentFieldIndex + 2)
    .keySeq()
    .first();
  return getFieldInfoFromName(nextField);
};

export const getPreviousResponseFieldInfo = (
  currentFieldInfo,
  responses,
  previousFieldInfo
) => {
  const responseKeys = responses.keySeq();
  let currentFieldIndex = responseKeys.findIndex(
    k => k === currentFieldInfo.fieldName
  );

  // Response does not exist - so try previous field if we have one
  if (currentFieldIndex === -1 && previousFieldInfo != null) {
    return getPreviousResponseFieldInfo(previousFieldInfo, responses);
  }

  // no preceding field
  if (currentFieldIndex === -1 || currentFieldIndex - 1 < 0) {
    return null;
  }

  //Otherwise grab previous response
  const prevField = responses
    .slice(currentFieldIndex - 1, currentFieldIndex)
    .keySeq()
    .first();
  return getFieldInfoFromName(prevField);
};

export const getFirstFieldOfSection = sectionId => {
  const questions = store
    .getState()
    .newQuestionnaire.questionnaire.questions.filter(
      x => x.section.id === sectionId
    )
    .sort((x, y) => {
      return x.sequence - y.sequence;
    });
  const firstQuestionNextSection = questions[0];
  return getFieldInfoFromName(
    getFieldName(
      firstQuestionNextSection.id,
      firstQuestionNextSection.section.id
    )
  );
};

export const getNextSectionId = sectionId => {
  const sections = store
    .getState()
    .newQuestionnaire.questionnaire.sections.filter(
      x => x.repeatable === false && x.id > sectionId
    );

  return sections.length > 1 ? sections[0].id : null;
};

export const getNonRepeatableSectionId = currentFieldInfo => {
  // This calls itself recursively to find our first no repeating section id
  // If we have no instance id then we're not repeating and can just return the section id
  if (currentFieldInfo.sectionInstanceId == null) {
    return currentFieldInfo.sectionId;
  } else {
    const contextKey = getContextKey(
      currentFieldInfo.sectionId,
      currentFieldInfo.sectionInstanceId
    );
    const context = store.getState().newQuestionnaire.contexts.get(contextKey);
    const sourceField = getFieldInfoFromName(context.get('SectionSourceField'));

    if (sourceField.sectionInstanceId == null) {
      return sourceField.sectionId;
    } else {
      return getNonRepeatableSectionId(sourceField);
    }
  }
};

export const getRootResponseFieldInfo = currentFieldInfo => {
  if (currentFieldInfo.sectionInstanceId != null) {
    const contextKey = getContextKey(
      currentFieldInfo.sectionId,
      currentFieldInfo.sectionInstanceId
    );
    const context = store.getState().newQuestionnaire.contexts.get(contextKey);
    const sourceField = getFieldInfoFromName(context.get('SectionSourceField'));

    if (sourceField.sectionInstanceId == null) {
      return sourceField;
    } else {
      return getRootResponseFieldInfo(sourceField);
    }
  } else {
    return currentFieldInfo;
  }
};

export const getSectionResponses = sectionId => {
  const questionnaires = store.getState().newQuestionnaire;
  const questionnaire = questionnaires.questionnaire;
  const repeatableSectionIds = questionnaire.sections
    .filter(x => x.repeatable)
    .map(x => x.id);
  const responses = questionnaires.responses;
  const responseKeys = responses.keySeq();

  // Find index of first question in this section's responses
  const firstIndex = responseKeys.findIndex(
    x => getFieldInfoFromName(x).sectionId === sectionId
  );

  if (firstIndex === -1) {
    return;
  }

  // Find index of a different non repeating section id
  const sectionResponseKeys = responseKeys.slice(firstIndex);
  let lastIndex = sectionResponseKeys.findIndex(x => {
    const fieldInfo = getFieldInfoFromName(x);
    return (
      fieldInfo.sectionId !== sectionId &&
      !repeatableSectionIds.includes(fieldInfo.sectionId)
    );
  });

  lastIndex = lastIndex !== -1 ? lastIndex + firstIndex : -1;
  return lastIndex !== -1
    ? responses.slice(firstIndex, lastIndex)
    : responses.slice(firstIndex);
};

export const getOrdinal = n => {
  return (
    n +
    (n > 0
      ? ['th', 'st', 'nd', 'rd'][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10]
      : '')
  );
};

export const coerceTypes = input => {
  return isNumeric(input)
    ? parseFloat(input)
    : isBoolean(input)
    ? input.trim().toLowerCase() === 'true'
    : input;
};

export const emptyObject = {};

export const emptyResponse = (fieldInfo, displaySectionId) => {
  const response = getFieldInfoFromName(fieldInfo.fieldName);
  response.question = '';
  response.value = '';
  response.key = '';
  response.displaySectionId = displaySectionId;
  return response;
};

export const emptyPair = {
  key: '',
  value: ''
};

export const defaultSectionStatus = sections => {
  const defaultSectionStatuses = {};
  sections
    .filter(x => x.repeatable === false)
    .forEach(
      x =>
        (defaultSectionStatuses[x.id.toString()] =
          SectionStatusConstants.NotStarted)
    );
  return defaultSectionStatuses;
};

export const getSectionIdFromName = sectionName => {
  const sections = store.getState().newQuestionnaire?.questionnaire?.sections;

  const section = sections?.find(
    section => sectionName.toLowerCase() === section.name.toLowerCase()
  );

  if (!section) 
    return null; // throw `Could not locate a section named ${sectionName}`;
  return section?.id;
};

export const getQuestionIdFromAlias = alias => {
  const questions = store.getState().newQuestionnaire?.questionnaire?.questions;

  const question = questions?.find(
    question => question.alias.toLowerCase() === alias.toLowerCase()
  );

  if (!question) 
    return null; // throw `Could not locate a question with alias ${alias}`;
  return question?.id;
};
